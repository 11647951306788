import React from 'react';
import routes from "../routes";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {getProcessingGroups, getCreativesConfigs} from "../photolab/config";
import creativeGroups from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import ErrorView from "../components/ErrorView";
import clientStorage from "../utils/client-storage";
import processingManager from "../photolab/ProcessingManager";
import {debounce} from "../utils/etc";
import {generatePath} from "react-router";
import { v4 as uuidv4 } from "uuid";

export default class ProcessingPage extends React.Component {

  state = {
    error: null,
  };

  processingTimerId = null;
  processingTimer = 0;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const locationState = this.props.location.state || {};
    const files = locationState.files;
    const maskFiles = locationState.maskFiles;
    const processing = null; // processingManager.restore();

    if (processing) {
      this.context.showLoader(true);
      processingManager.start(processing);
    } else if (files) {
      this.context.showLoader(true);
      this.startProcessing(files, maskFiles);
    } else {
      this.props.history.replace(generatePath(routes.INDEX));
    }
  }

  componentWillUnmount() {
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    return;

    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  startProcessing = (files, maskFiles) => {
    clientStorage.incrementSelectedPhotosAmount();

    hitEvent(hits.PROCESSING_START);

    const {
      seeds,
      num_steps,
      prompt,
      negativePrompt,
      control_weights,
      control_modes,
      canvas_template_name,
      preprocessing_template_id,
    } = this.props.location.state;

    const groups = getProcessingGroups();
    const processing = new Processing();
    processing.setId(uuidv4());
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setExtra("seeds", seeds);
    processing.setExtra("prompt", prompt);
    processing.setExtra("negativePrompt", negativePrompt);
    processing.setExtra("num_steps", num_steps);
    processing.setExtra("control_weights", control_weights);
    processing.setExtra("control_modes", control_modes);
    processing.setExtra("canvas_template_name", canvas_template_name);
    processing.setExtra("preprocessing_template_id", preprocessing_template_id);

    files.forEach((file, index) => {
      processing.setFile(file, [index]);
    });

    maskFiles && maskFiles.forEach((file, index) => {
      processing.setFile(file, [`m${index}`]);
    });

    processingManager.start(processing);

    this.startCreatives(
      processing,
      seeds,
      prompt,
      negativePrompt,
      num_steps,
      control_weights,
      control_modes,
      canvas_template_name,
      preprocessing_template_id
    );
    this.startProcessingTimer();
  };

  startCreatives = (
    processing,
    seeds,
    prompt,
    negativePrompt,
    num_steps,
    control_weights,
    control_modes,
    canvas_template_name,
    preprocessing_template_id
  ) => {
    processing.files.forEach((file, index) => {
      getCreativesConfigs(
        index,
        seeds,
        prompt,
        negativePrompt,
        num_steps,
        control_weights,
        control_modes,
        canvas_template_name,
        preprocessing_template_id
      ).forEach((creativeConfig) => {
        const creative = new Creative().configureByConfig(creativeConfig, index);
        creative.setAsSelected(true);
        creative.setExtra("file", file);
        processing.addCreative(creative);
      });
    });

    processingManager.update();
  }

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => debounce("ProcessingPage_handleProcessingChange", 100, () => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedAndStartedCreatives = processing.creatives.filter((c) => {
      return c.isSelected && c.group !== creativeGroups.COMMON;
    });

    if (selectedAndStartedCreatives.isEmpty()) {
      return;
    }

    const genderCreative = processing.creatives.find((c) => {
      return c.group === creativeGroups.COMMON && c.templateId === "gender";
    });

    if (genderCreative && genderCreative.isPending) {
      return;
    }

    const processedCreatives = selectedAndStartedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedAndStartedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      this.stopProcessingTimer();

      clientStorage.incrementProcessedPhotosAmount();
      if (!clientStorage.hasFirstProcessingProcessedAt()) {
        clientStorage.setFirstProcessingProcessedAt(Date.now());
      }

      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      hitEvent(hits.PROCESSING_PROCESSED);
      logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
      logProcessingsTimings(elapsedMs);

      this.props.history.replace(generatePath(routes.RESULT, {id: processing.id}));
    }
    else if (failedCreatives.length === selectedAndStartedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];

      hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
      }

      processingManager.clear();

      this.setState({
        error: failedCreative.error,
      }, this.context.hideLoader);
    }
  });

  handleProcessingTimeout = () => {
    processingManager.clear();

    this.setState({
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    }, this.context.hideLoader);
  };

  handleFileSelected = (file) => {
    this.props.history.replace(routes.PHOTOCHOOSER, {files: [file]});
  };

  handleGoToStart = () => {
    this.props.history.replace(routes.INDEX);
  };

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onStart={this.handleGoToStart}
      />;
    }

    return <React.Fragment />;
  }
}

ProcessingPage.contextType = AppContext;
