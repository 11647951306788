import React from 'react';
import AppContext from "../../contexts/AppContext";
import {mapSelectedImageItem, webviewAnalyticsEvent, webviewCall} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import uploadHandler from "../../utils/upload.handler";
import routes from "../../routes";
import i18n from "../../i18n";
import {debounce} from "../../utils/etc";
import ImageErrorModal from "./ImageErrorModal";
import {imageStatus} from "./shared";
import {Button, Col, Container, Figure, Form, Row, Stack} from "react-bootstrap";
import * as api from "../../utils/api";
import {v4 as uuidv4} from "uuid";
import {generatePath} from "react-router";

const predefineds = [
  {
    seeds: 3806464073,
    prompt: "(style of sam bosma), trending on artstation, masterpiece, beautiful illustration"
  },
  {
    seeds: 324,
    prompt: "(style of steve lieber), trending on artstation, masterpiece, beautiful illustration",
  },
].map((item) => {
  item.id = uuidv4();
  return item;
});

const canvasTemplateName = [
  {
    value: 8700,
    label: "кроп",
  },
  {
    value: 6730,
    label: "кроп по пояс",
  },
  {
    value: 8659,
    label: "без кропа",
  },
];

export default class PhotochooserPage extends React.Component {

  state = {
    images: clientStorage.getLatestSelectedImages(),
    promptText: predefineds[0].prompt,
    negativePromptText: "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo, lowres, text,cropped, low quality,( poorly drawn :1.2) ,normal quality, text font ui, error, bad",
    seeds: "" + predefineds[0].seeds,
    num_steps: "15",
    predefinedId: predefineds[0].id,
    control_weights: "1, 1, 1, 0",
    control_modes: "1, 1, 2, 2",
    canvas_template_name: canvasTemplateName[0].value,
    preprocessing_template_id: "",
  };

  fileFieldRef = React.createRef();
  imageTasks = [];
  imagesNumber = this.state.images.isNotEmpty()
    ? this.state.images.max((i) => i.number)
    : 0;

  constructor(props) {
    super(props);

    const loadedUrl = new URL(window.location.href);
    if (loadedUrl.searchParams.has("inputdata")) {
      try {
        const inputData = JSON.parse(loadedUrl.searchParams.get("inputdata"));

        if (inputData.seeds && inputData.seeds.length > 0) {
          this.state.seeds = Array.isArray(inputData.seeds) ? inputData.seeds.join(",") : inputData.seeds;
        }

        if (inputData.num_steps && inputData.num_steps.length > 0) {
          this.state.num_steps = Array.isArray(inputData.num_steps) ? inputData.num_steps.join(",") : inputData.num_steps;
        }

        if (inputData.prompt && inputData.prompt.length > 0) {
          this.state.promptText = inputData.prompt;
        }

        if (inputData.negativePrompt && inputData.negativePrompt.length > 0) {
          this.state.negativePromptText = inputData.negativePrompt;
        }

        if (inputData.control_weights) {
          this.state.control_weights = inputData.control_weights;
        }

        if (inputData.control_modes) {
          this.state.control_modes = inputData.control_modes;
        }

        if (inputData.canvas_template_name) {
          this.state.canvas_template_name = inputData.canvas_template_name;
        }

        if (inputData.preprocessing_template_id) {
          this.state.preprocessing_template_id = inputData.preprocessing_template_id;
        }
      } catch (e) {
        console.error("INPUTDATA error", e);
      }
    }
  }

  componentDidMount() {
    this.context.showLoader();

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          setTimeout(() => {
            window.location.replace("/?r=" + Math.random())
          }, 1000);
        } else {
          this.context.hideLoader();
        }
      })
      .catch((err) => {
        window.location.replace("/?r=" + Math.random());
      });

    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    this.checkPendingImages();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
    window.webviewEventsListeners.backPress.pop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    clientStorage.setLatestSelectedImages(this.state.images.map((image) => {
      return Object.assign({}, image, {checked: false});
    }));

    this.checkPendingImages();
  }

  checkPendingImages = () => {
    debounce("PhotochooserPage_checkPendingImages", 200, () => {
      this.state.images
        .filter((image) => image.status === imageStatus.pending)
        .forEach((image, index) => this.checkImage(image, index));
    });
  };

  requestPhotoChooser = () => {
    webviewCall("nativePhotoSelect", {
      func: "onNativeAppPhotoSelected",
      use_crop: 0,
      num_photos: 10,
      show: "gallery",
      tab: "faces",
      neurocamera: 1,
    });
  };

  checkImage = (image) => {
    if (this.imageTasks[image.url]) {
      return;
    }

    image.status = imageStatus.valid;
    this.setState({images: this.state.images.slice()});

    this.imageTasks[image.url] = Promise.resolve(image);
  };

  handleWebviewFileSelected = (data) => {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const stateImages = this.state.images.slice();

    data.photos
      .map((image) => mapSelectedImageItem(image))
      .forEach((image) => {
        if (stateImages.findIndex((_) => _.url === image.url) === -1) {
          image.status = imageStatus.pending;
          image.number = ++this.imagesNumber;
          stateImages.push(image);

          webviewAnalyticsEvent("photo_selected", [
            "",
            "photo_chooser",
            clientStorage.getSelectedPhotosAmount() + 1,
            image.number,
          ]);
        }
      });

    this.setState({
      images: stateImages.filter((i) => i.status !== imageStatus.invalid)
    });
  };

  handleBrowserFileSelected(images) {
    this.context.showLoader();

    const stateImages = this.state.images.slice();

    Promise.all([...images].map((image) => uploadHandler(image).catch(() => {/* ignore */})))
      .then((images) => {
        images
          .filter(Boolean)
          .forEach((image) => {
            image.status = imageStatus.pending;
            image.number = ++this.imagesNumber;
            stateImages.push(image);
          });

        this.setState({
          images: stateImages.filter((i) => i.status !== imageStatus.invalid)
        }, this.context.hideLoader);
      })
      .catch(console.error);
  }

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((_) => _.url === image.url);

    if (pos > -1) {
      this.state.images.splice(pos, 1);
      this.setState({
        images: this.state.images.slice(),
      });
    }
  };

  handleImageClick = (image) => {
    if (image.status === imageStatus.invalid) {
      this.context.pushModal(<ImageErrorModal
        key="CreatePage_ImageErrorModal"
        image={image}
        hideCloseButton={true}
        onOkClick={() => this.handleRemoveImage(image)}
      />);
      return;
    }

    this.handleRemoveImage(image);
  };

  handleProcessClick = () => {
    this.handleProcess();
  };

  handleProcess = () => {
    const images = this.state.images
      .filter((image) => image.status === imageStatus.valid);

    if (images.isEmpty()) {
      return;
    }

    this.props.history.push(generatePath(routes.PROCESSING), {
      files: images,
      seeds: ("" + this.state.seeds)
        .split(",")
        .map((item) => item.trim()),
      num_steps: this.state.num_steps
        .split(",")
        .map((item) => item.trim()),
      prompt: this.state.promptText,
      negativePrompt: this.state.negativePromptText,
      control_weights: this.state.control_weights.trim(),
      control_modes: this.state.control_modes.trim(),
      canvas_template_name: this.state.canvas_template_name,
      preprocessing_template_id: this.state.preprocessing_template_id,
    });
  };

  handleAddPhotosClick = () => {
    if (window.clientConfig.isWeb) {
      if (this.fileFieldRef) {
        this.fileFieldRef.value = "";
        this.fileFieldRef.click();
      }
    } else {
      this.requestPhotoChooser();
    }
  };

  handlePredefinedItemSelected = (id) => {
    const predefined = predefineds.find((item) => item.id === id);

    this.setState({
      predefinedId: predefined.id,
      seeds: predefined.seeds,
      promptText: predefined.prompt,
    });
  };

  handleRandomSeedsButtonClick = () => {
    const seeds = [];
    while (seeds.length < 10) {
      const seed = Math.ceil(Math.random() * 100_000);
      if (seeds.includes(seed)) {
        continue;
      }

      seeds.push(seed);
    }

    this.setState({seeds: seeds.join(", ")});
  };

  render() {
    const validImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.valid;
    });

    return <React.Fragment>
      <Container fluid="md" className="mt-2 mb-2">

        <Stack>
          <Form.Group className="mb-3" controlId="form.predefined">
            <Form.Label>Predefined</Form.Label>
            <Form.Select value={this.state.predefinedId} onChange={(e) => this.handlePredefinedItemSelected(e.target.value)}>
              {predefineds.map((item) => <option key={item.id} value={item.id}>
                SEEDS: {item.seeds}, PROMPT: {item.prompt}
              </option>)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.seeds">
            <Form.Label>
              Seeds (comma separated)
              <Button variant="link" size="sm" onClick={this.handleRandomSeedsButtonClick}>Random</Button>
            </Form.Label>
            <Form.Control as="input" type="text" value={this.state.seeds} onChange={(e) => this.setState({seeds: e.target.value})} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.seeds">
            <Form.Label>Numsteps (comma separated)</Form.Label>
            <Form.Control as="input" type="text" value={this.state.num_steps} onChange={(e) => this.setState({num_steps: e.target.value})} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.prompt">
            <Form.Label>Prompt</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={this.state.promptText}
              onChange={(e) => this.setState({promptText: e.target.value})}
            />
          </Form.Group>
          <Form.Group controlId="form.negativePrompt">
            <Form.Label>Negative prompt</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={this.state.negativePromptText}
              onChange={(e) => this.setState({negativePromptText: e.target.value})}
            />
          </Form.Group>

          <hr />

          {["control_weights", "control_modes"].map((key) => {
            return <Form.Group key={key}>
              <Form.Label>{key}</Form.Label>
              <Form.Control
                value={this.state[key]}
                onChange={(e) => this.setState({[key]: e.target.value})}
              />
            </Form.Group>
          })}

          <hr />

          {canvasTemplateName.map((item) => <Form.Group key={item.value}>
            <Form.Check
              name="canvas_template_name"
              type="radio"
              label={item.label}
              value={item.value}
              checked={this.state.canvas_template_name === item.value}
              onChange={(e) => e.target.checked && this.setState({canvas_template_name: item.value})}
            />
          </Form.Group>)}

          <hr />

          <Form.Group className="mb-3" controlId="form.preprocessing_template_id">
            <Form.Label>Pre Template</Form.Label>
            <Form.Control as="input" type="text" value={this.state.preprocessing_template_id} onChange={(e) => this.setState({preprocessing_template_id: e.target.value})} />
          </Form.Group>
        </Stack>

        <hr />

        <div>
          <Button onClick={this.handleAddPhotosClick} size="lg">
            {i18n.t("button__select_photos")}
          </Button>
          &nbsp;
          <Button onClick={this.handleProcessClick} hidden={validImagesCount < 1} variant="success" size="lg">
            {i18n.t("button__proceed")}
          </Button>
        </div>

        <Row className="mt-3" hidden={this.state.images.isEmpty()}>
          {this.state.images.map((image) => <Col key={image.url} xs={6} md={3} lg={2}>
            <Figure onClick={() => this.handleImageClick(image)} style={{margin: 0}}>
              <Figure.Image rounded src={image.url} />
            </Figure>
          </Col>)}
        </Row>

      </Container>

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        multiple
        ref={(ref) => this.fileFieldRef = ref}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => this.handleBrowserFileSelected(e.target.files)}
      />

    </React.Fragment>;
  }
}

PhotochooserPage.contextType = AppContext;
