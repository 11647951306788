import {combo} from "./helpers";
import Creative from "../Creative";
import groups from "./groups";

export function getProcessingGroups() {
  return [
    groups.g8777_8698,
  ];
}

export function getCreativesConfigs(
  fileIndex,
  seeds,
  prompt,
  negativePrompt,
  num_steps,
  control_weights,
  control_modes,
  canvas_template_name,
  preprocessing_template_id
) {
  const results = [];
  const positions = [
    {
      group: groups.lineart,
      params: {
        template_name: 8815,
        canvas_template_name,
        mask_template_name: 8205,
        ctrlnet_modules: "depth_midas, softedge_hed, lineart_realistic, t2ia_color_grid",
        control_weights,
        control_modes,
        thresholds: "[[0.15, 24], [64, 64], [64, 64], [64, 64]]",
        pixel_perfects: "true, true, true, true",
      },
    },
    {
      group: groups.sketch,
      params: {
        template_name: 8814,
        canvas_template_name,
        mask_template_name: 8205,
        canvas_target: 2,
      }
    },
  ];

  positions.forEach((position) => {
    seeds.forEach((seed) => {
      num_steps.forEach((num_step) => {
        const templateParams = {
          ...position.params,
          seeds: seed,
          num_steps: num_step,
          prompt,
          negative_prompt: negativePrompt,
        };

        if (position.params.canvas_template_name === 6730) {
          templateParams.width = 768;
          templateParams.height = 1024;
        } else if (position.params.canvas_template_name === 8659) {
          templateParams.width = 768;
          templateParams.height = 1024;
        } else if (position.params.canvas_template_name === 8700) {
          templateParams.canvas_target = 2;
        }

        delete templateParams.template_name;

        const mainTemplateId = position.params.template_name;
        const combo = createCombo(fileIndex, templateParams, mainTemplateId, preprocessing_template_id);
        combo.setGroup(position.group);
        combo.setExtra("group_1", position.group);
        combo.setExtra("group_2", seed);
        combo.setExtra("group_3", num_steps);

        results.push(combo);
      });
    });
  });

  return results;
}

function createCombo(fileIndex, templateParamsArg, mainTemplateId, preprocessing_template_id) {
  const templateParams = {
    ...templateParamsArg,
    gender: "#0",
  };

  const fileImages = [{src: "@" + fileIndex}];
  const preprocessingTemplateId = parseInt(preprocessing_template_id);

  const steps = [
    {id: "gender_classifier"},
  ];

  if (preprocessing_template_id) {
    steps.push({
      id: preprocessingTemplateId,
      images: fileImages,
    });
  }

  steps.push({
    id: mainTemplateId,
    ...(preprocessingTemplateId ? {} : {images: fileImages}),
    templateParams
  });

  let nameSegments = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      nameSegments.push(step.id);
    }
  })

  const name = nameSegments.join("_") + ",seed:" + templateParams.seeds + `,str:${templateParams.num_steps}`;
  const item = combo(steps);
  item.setExtra(Creative.EXTRA_NAME, name);

  return item;
}
