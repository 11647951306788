import React from 'react';
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import processingManager from "../../photolab/ProcessingManager";
import {generatePath} from "react-router";
import clientStorage from "../../utils/client-storage";
import {saveAs} from "file-saver";
import Processing from "../../photolab/Processing";
import * as api from "../../utils/api";
import {
  Alert,
  Button,
  Col,
  Container,
  Figure,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Nav,
  Row,
  Spinner,
  Stack
} from "react-bootstrap";
import {creativeName, transformToDownloadUrl} from "../../utils/creative";
import {getCreativesConfigs} from "../../photolab/config";
import Creative from "../../photolab/Creative";
import {copyTextWithInvisibleElement} from "../../utils/text";

export default class ResultPage extends React.Component {

  state = {
    file: null,
    files: [],
    isReady: false,
    updatedAt: 0,
    selectedCreative: null,
    promptText: null,
    negativePromptText: null,
    seeds: null,
    num_steps: null,
    group: null,
    group1: null,
    group2: null,
    group3: null,
    faceTemplateChain: null,
    control_weights: null,
    control_modes: null,
    canvas_template_name: null,
    preprocessing_template_id: null,
    originalImageIsShown: false,
    configForm: null,
  };

  componentDidMount() {
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      this.fetchProcessing(this.props.match.params.id);
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }

    document.body.addEventListener("keydown", this.handleKeydown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match && (prevProps.match.params.id !== this.props.match.params.id)) {
      this.context.showLoader();
      this.fetchProcessing(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
    document.body.removeEventListener("keydown", this.handleKeydown);
  }

  fetchProcessing = (processingId) => {
    api.sharedProcessingsGet(processingId)
      .then((data) => {
        const restoredProcessing = new Processing();
        restoredProcessing.fromObject(data);
        processingManager.start(restoredProcessing);

        const url = new URL(window.location.href);
        if (url.searchParams.get("regenerate") === "1") {
          this.regenerateCreatives();
        }

        const groups1 = restoredProcessing.creatives.map((c) => c.getExtra("group_1")).unique();
        const groups2 = restoredProcessing.creatives.map((c) => c.getExtra("group_2")).unique();

        this.setState({
          isReady: true,
          group1: groups1.first(),
          group2: groups2.first(),
          seeds: restoredProcessing.getExtra("seeds"),
          num_steps: restoredProcessing.getExtra("num_steps"),
          promptText: restoredProcessing.getExtra("prompt"),
          negativePromptText: restoredProcessing.getExtra("negativePrompt"),
          faceTemplateChain: restoredProcessing.getExtra("faceTemplateChain"),
          control_weights: restoredProcessing.getExtra("control_weights"),
          control_modes: restoredProcessing.getExtra("control_modes"),
          canvas_template_name: restoredProcessing.getExtra("canvas_template_name"),
          preprocessing_template_id: restoredProcessing.getExtra("preprocessing_template_id"),
        });
      })
      .catch((err) => {
        console.error(err);
        this.props.history.replace(generatePath(routes.INDEX));
      })
  };

  handleKeydown = (e) => {
    if (!this.state.selectedCreative) {
      return;
    }

    const creatives = processingManager.processing.creatives
      .filter((c) => c.getExtra("file").url === this.state.file.url);

    const selectedCreativeIndex = creatives.findIndex((c) => c.id === this.state.selectedCreative.id);
    let nextCreative;

    if (e.key === "ArrowLeft" && selectedCreativeIndex > 0) {
      nextCreative = creatives[selectedCreativeIndex - 1];
    }

    if (e.key === "ArrowRight" && selectedCreativeIndex < (creatives.length - 1)) {
      nextCreative = creatives[selectedCreativeIndex + 1];
    }

    const targetTag = e.target.tagName.toLowerCase();

    if ((e.key === "d" || e.key === "в") && targetTag !== "input" && targetTag !== "textarea") {
      this.startDownloadImageUrl(this.state.selectedCreative.result);
    }

    if (nextCreative) {
      const processing = processingManager.processing;
      const url = new URL(window.location.href);
      url.searchParams.set("cid", nextCreative.id);

      this.props.history.replace({
        pathname: generatePath(routes.RESULT, {id: processing.id}),
        search: url.search
      });

      this.setState({selectedCreative: nextCreative});
    }
  }

  handleProcessingChanged = () => {
    clientStorage.setLatestSelectedImages([]);

    const processing = processingManager.processing;
    const url = new URL(window.location.href);

    const selectedFile = (url.searchParams.has("photoIndex") && processing.files[url.searchParams.get("photoIndex")])
      || processing.getExtra(Processing.SELECTED_GROUP)
      || processing.files[0];

    const selectedCreative = processing.creatives.find((c) => {
      return c.id === url.searchParams.get("cid");
    });

    this.context.hideLoader();

    const groups1 = processing.creatives.map((c) => c.getExtra("group_1")).unique();
    const groups2 = processing.creatives.map((c) => c.getExtra("group_2")).unique();

    this.setState({
      file: selectedFile,
      files: [...processing.files],
      selectedCreative: selectedCreative || null,
      updatedAt: Date.now(),
      group: this.state.group || url.searchParams.get("group") || processing.groups.first(),
      group1: this.state.group1 || url.searchParams.get("group1") || groups1.first(),
      group2: this.state.group2 || url.searchParams.get("group2") || groups2.first(),
      group3: this.state.group3 || (url.searchParams.has("group3") ? decodeURIComponent(url.searchParams.get("group3")) : null),
      seeds: this.state.seeds || processing.getExtra("seeds"),
      num_steps: this.state.num_steps || processing.getExtra("num_steps"),
      promptText: this.state.promptText || processing.getExtra("prompt"),
      negativePromptText: this.state.negativePromptText || processing.getExtra("negativePrompt"),
      faceTemplateChain: this.state.faceTemplateChain || processing.getExtra("faceTemplateChain"),
      control_weights: this.state.control_weights || processing.getExtra("control_weights"),
      control_modes: this.state.control_modes || processing.getExtra("control_modes"),
      canvas_template_name: this.state.canvas_template_name || processing.getExtra("canvas_template_name"),
      preprocessing_template_id: this.state.preprocessing_template_id || processing.getExtra("preprocessing_template_id"),
    });
  };

  handleBackButtonClick = () => {
    processingManager.clear();
    window.location.href = "/?r=" + Math.random();
  };

  handleCopyLink = () => {
    this.context.showToast({
      message: "Copied",
      delay: 2000,
    });

    copyTextWithInvisibleElement(window.location.href);
  };

  startDownloadImageUrl = (imageUrl) => {
    const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
    saveAs(transformToDownloadUrl(imageUrl), fileName);
  };

  handleTabClick = (file) => {
    if (file.url === this.state.file.url) {
      return;
    }

    const processing = processingManager.processing;

    processing.setExtra(Processing.SELECTED_GROUP, file);
    processingManager.update();

    const url = new URL(window.location.href);
    const fileIndex = processing.files.findIndex((f) => f.url === file.url);

    if (fileIndex > -1) {
      url.searchParams.set("photoIndex", fileIndex);
    }

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });

    this.setState({file});
  }

  handleCreativeClick = (creative) => {
    this.setState({selectedCreative: creative});

    const processing = processingManager.processing;

    const url = new URL(window.location.href);
    url.searchParams.set("cid", creative.id);

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });
  };

  handleConfigFormShow = (creative) => {
    const params = creative.getExtra(Creative.EXTRA_COMBO_STEPS, [])
      .map((s) => s.templateParams ? {...{template_name: s.id}, ...s.templateParams} : null)
      .filter((s) => s)
      .last();

    this.setState({
      configForm: {
        name: {
          value: "",
          error: "",
        },
        params: {
          value: JSON.stringify(params, undefined, 4),
          error: "",
        },
        comment: {
          value: "",
          error: "",
        },
      }
    });
  };

  handleConfigFormHide = () => {
    this.setState({
      configForm: null,
    });
  }

  handleConfigFormChangeName = (e) => {
    this.setState({
      configForm: {
        error: "",
        name: {
          value: e.target.value,
          error: "",
        },
        params: this.state.configForm.params,
        comment: this.state.configForm.comment,
      }
    });
  }

  handleConfigFormChangeParams = (e) => {
    this.setState({
      configForm: {
        error: "",
        name: this.state.configForm.name,
        params: {
          value: e.target.value,
          error: "",
        },
        comment: this.state.configForm.comment,
      }
    });
  }

  handleConfigFormChangeComment = (e) => {
    this.setState({
      configForm: {
        error: "",
        name: this.state.configForm.name,
        params: this.state.configForm.params,
        comment:  {
          value: e.target.value,
          error: "",
        },
      }
    });
  }

  handleConfigFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let hasError = false;
    const nextState = this.state.configForm;
    nextState.error = "";

    const name = this.state.configForm.name.value.trim();

    if (!name) {
      nextState.name.error = "Name is a required field";
      hasError = true;
    }

    let params = this.state.configForm.params.value.trim();

    try {
      params = JSON.parse(params);
    } catch (e) {
      nextState.params.error = e.message;
      hasError = true;
    }

    if (hasError) {
      this.setState({
        configForm: nextState,
      });

      return;
    }

    nextState.isLoading = true;

    this.setState({
      configForm: nextState,
    });

    const url = new URL(window.location.href);
    url.searchParams.set("cid", this.state.selectedCreative.id);

    api.configStore({
      name,
      params,
      comment: this.state.configForm.comment.value,
      designers_admin_url: url.toString(),
      designers_result_url: this.state.selectedCreative.result,
    })
      .then((res) => {
        if (res.error && res.error.name) {
          nextState.name.error = res.error.name;
        }

        if (res.error && res.error.params) {
          nextState.params.error = res.error.params;
        }

        if (res.error && typeof res.error === "string") {
          nextState.error = res.error;
        }

        if (!res.error) {
          nextState.success = true;
        }

        nextState.isLoading = false;

        this.setState({
          configForm: nextState,
        });
      })
      .catch((e) => {
        console.error(e);

        nextState.isLoading = false;
        nextState.error = "Internal error";

        this.setState({
          configForm: nextState,
        });
      });
  }

  handleGroupSelected = (groupName, group) => {
    const processing = processingManager.processing;

    const url = new URL(window.location.href);
    url.searchParams.set(groupName, encodeURIComponent(group));

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });

    this.setState({[groupName]: group});

    if (groupName === "group2") {
      const groups3 = processingManager.processing.creatives
        .filter((creative) => {
          return creative.getExtra("file").url === this.state.file.url
            && creative.getExtra("group_1") === this.state.group1
            && creative.getExtra("group_2") === group
            ;
        })
        .map((c) => c.getExtra("group_3"))
        .unique();

      this.setState({group3: groups3.first()});
    }
  }

  handleCloseModal = () => {
    const processing = processingManager.processing;
    const url = new URL(window.location.href);
    url.searchParams.delete("cid");

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search
    });

    this.setState({selectedCreative: null});
  };

  getDataForFork = () => {
    const processing = processingManager.processing;
    const data = {
      seeds: processing.getExtra("seeds", ""),
      prompt: processing.getExtra("prompt", ""),
      negativePrompt: processing.getExtra("negativePrompt", ""),
      num_steps: processing.getExtra("num_steps", ""),
      faceTemplateChain: processing.getExtra("faceTemplateChain", ""),
      control_weights: processing.getExtra("control_weights", ""),
      control_modes: processing.getExtra("control_modes", ""),
      canvas_template_name: processing.getExtra("canvas_template_name", ""),
      preprocessing_template_id: processing.getExtra("preprocessing_template_id", ""),
    };

    return encodeURIComponent(JSON.stringify(data));
  };

  handleRegenerateButtonClick = () => {
    if (!window.confirm("Sure?")) {
      return;
    }

    this.context.showLoader();

    const processing = processingManager.processing;
    const processingUrlWithRegenerate = generatePath(routes.RESULT, {id: processing.id}) + "?regenerate=1&r=" + Math.random();

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          window.location.replace(processingUrlWithRegenerate);
        } else {
          this.regenerateCreatives();
        }
      })
      .catch((err) => {
        window.location.replace(processingUrlWithRegenerate);
      });
  };

  handleRegenerateNewPromptButtonClick = () => {
    const files = processingManager.processing.files;

    processingManager.stop();
    processingManager.clear();

    this.context.showLoader(true, () => {
      this.props.history.push({
        pathname: generatePath(routes.PROCESSING),
        state: {
          files,
          seeds: ("" + this.state.seeds)
            .split(",")
            .map((item) => item.trim()),
          num_steps: ("" + this.state.num_steps)
            .split(",")
            .map((item) => item.trim()),
          prompt: this.state.promptText,
          negativePrompt: this.state.negativePromptText,
          faceTemplateChain: this.state.faceTemplateChain,
          control_weights: this.state.control_weights,
          control_modes: this.state.control_modes,
          canvas_template_name: this.state.canvas_template_name,
          preprocessing_template_id: this.state.preprocessing_template_id,
        },
      });
    });
  };

  regenerateCreatives = () => {
    const processing = processingManager.processing;
    const seeds = processing.getExtra("seeds");
    const num_steps = processing.getExtra("num_steps");
    const prompt = processing.getExtra("prompt");
    const negativePrompt = processing.getExtra("negativePrompt");
    const faceTemplateChain = processing.getExtra("faceTemplateChain");
    const control_weights = processing.getExtra("control_weights");
    const control_modes = processing.getExtra("control_modes");
    const canvas_template_name = processing.getExtra("canvas_template_name");
    const preprocessing_template_id = processing.getExtra("preprocessing_template_id");

    processing.creatives.splice(0, processing.creatives.length);
    processing.files.forEach((file, index) => {
      getCreativesConfigs(
        index,
        seeds,
        prompt,
        negativePrompt,
        num_steps,
        faceTemplateChain,
        control_weights,
        control_modes,
        canvas_template_name,
        preprocessing_template_id
      ).forEach((creativeConfig) => {
        const creative = new Creative().configureByConfig(creativeConfig, index);
        creative.setAsSelected(true);
        creative.setExtra("file", file);
        processing.addCreative(creative);
      });
    });

    processingManager.update();

    const url = new URL(window.location.href);
    url.searchParams.delete("regenerate");

    this.props.history.replace({
      pathname: generatePath(routes.RESULT, {id: processing.id}),
      search: url.search,
    });
  };

  handleMouseDown = () => {
    this.setState({originalImageIsShown: true});
  }

  handleMouseUp = () => {
    this.setState({originalImageIsShown: false});
  }

  render() {
    if (!this.context.loader.isHidden || !this.state.isReady) {
      return <React.Fragment />;
    }

    const allCreatives = processingManager.processing.creatives;
    const creatives = allCreatives.filter((creative) => {
      return creative.getExtra("file").url === this.state.file.url
        && creative.getExtra("group_1") === this.state.group1
        && creative.getExtra("group_2") === this.state.group2
        // && creative.getExtra("group_3") === this.state.group3
        ;
    });

    const groups1 = processingManager.processing.creatives.map((c) => c.getExtra("group_1")).unique();
    const groups2 = processingManager.processing.creatives.map((c) => c.getExtra("group_2")).unique();
    // const groups3 = processingManager.processing.creatives
    //   .filter((creative) => {
    //     return creative.getExtra("file").url === this.state.file.url
    //       && creative.getExtra("group_1") === this.state.group1
    //       && creative.getExtra("group_2") === this.state.group2
    //     ;
    //   })
    //   .map((c) => c.getExtra("group_3")).unique();

    const creativesItems = creatives.slice();
    const creativeBoxStyles = {width: "300px", display: "flex", justifyContent: "center", alignItems: "center", height: "inherit"};

    return <React.Fragment>

      <Modal show={this.state.selectedCreative != null} centered style={{"--bs-modal-width": "95%"}} onHide={this.handleCloseModal}>
        <Modal.Header className="gap-3" closeButton>
          <Modal.Title className="w-100">Template #{this.state.selectedCreative && creativeName(this.state.selectedCreative)}</Modal.Title>
          <div className="d-flex gap-2 flex-row w-100">
            <Button className="w-50" onClick={() => this.startDownloadImageUrl(this.state.selectedCreative.result)}>Download</Button>
            <Button className="w-50" onClick={this.handleCopyLink} variant="secondary">Copy link</Button>
            <Button className="w-50" onClick={() => {this.handleConfigFormShow(this.state.selectedCreative)}}>Save config</Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.selectedCreative && this.state.selectedCreative.isPending && <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>}
          <Stack gap={3}>
            {this.state.selectedCreative && this.state.selectedCreative.isProcessed && <React.Fragment>
              <Row>
                {this.state.selectedCreative.getExtra("file") && <Col>
                  <Image src={this.state.selectedCreative.getExtra("file").url} rounded fluid />
                </Col>}
                {this.state.selectedCreative.getFile("preprocessing") && <Col>
                  <Image src={this.state.selectedCreative.getFile("preprocessing")} rounded fluid />
                </Col>}
                {this.state.selectedCreative.result && <Col
                  className="result-modal"
                  style={{"--result": `url(${this.state.selectedCreative.result})`, "--displayRes": this.state.originalImageIsShown ? "none" : "flex"}}
                  onMouseDown={this.handleMouseDown}
                  onMouseUp={this.handleMouseUp}
                >
                  <Image
                    src={this.state.selectedCreative.getExtra("file").url}
                    rounded
                    fluid />
                </Col>}
              </Row>

            </React.Fragment>}
            {this.state.selectedCreative && Object.keys(this.state.selectedCreative.getTaskConfigs()).map((k) => <Alert variant="dark" key={k}>
              {this.state.selectedCreative.getTaskConfig(k)}
            </Alert>)}
          </Stack>
        </Modal.Body>
      </Modal>

      {this.state.configForm && <Modal show={true} fullscreen={true} centered onHide={this.handleConfigFormHide}>
        <Modal.Header className="gap-3" closeButton>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger" hidden={!this.state.configForm.error}>{this.state.configForm.error}</Alert>
          <Alert variant="success" hidden={!this.state.configForm.success}>
            Config saved <a href={"https://pw.photo-cdn.net/admin/photolab/configs/" + this.state.configForm.name.value} target="_blank">link</a>
          </Alert>
          <Form noValidate hidden={this.state.configForm.success} onSubmit={this.handleConfigFormSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={this.state.configForm.name.value}
                onChange={this.handleConfigFormChangeName}
                isInvalid={this.state.configForm.name.error}
                disabled={this.state.configForm.isLoading}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.configForm.name.error}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Params</Form.Label>
              <Form.Control
                as="textarea"
                rows={20}
                value={this.state.configForm.params.value}
                onChange={this.handleConfigFormChangeParams}
                isInvalid={this.state.configForm.params.error}
                disabled={this.state.configForm.isLoading}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.configForm.params.error}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={this.state.configForm.comment.value}
                onChange={this.handleConfigFormChangeComment}
                isInvalid={this.state.configForm.comment.error}
                disabled={this.state.configForm.isLoading}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.configForm.comment.error}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={this.state.configForm.isLoading}>
              Save
            </Button>
            {this.state.configForm.isLoading && <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}
          </Form>
        </Modal.Body>
      </Modal>}

      <Container fluid>
        <Row>
          <Col sm={2} className="bg-light pt-3 pb-3">

            <Button onClick={this.handleBackButtonClick}>Back to photochooser</Button>
            &nbsp;
            <a className="btn btn-dark" href={"/?inputdata=" + this.getDataForFork()} target="_blank">Fork</a>
            &nbsp;
            <Button variant="danger" onClick={this.handleRegenerateButtonClick}>Regenerate all</Button>

            <Stack className="mt-2" gap={2}>
              <FloatingLabel label="Seeds" controlId="form.seeds">
                <Form.Control
                  as="input"
                  type="text"
                  value={this.state.seeds}
                  onChange={(e) => this.setState({seeds: e.target.value})}
                />
              </FloatingLabel>
              <FloatingLabel label="Numsteps" controlId="form.num_steps">
                <Form.Control
                  as="input"
                  type="text"
                  value={this.state.num_steps}
                  onChange={(e) => this.setState({num_steps: e.target.value})}
                />
              </FloatingLabel>
              <FloatingLabel label="Prompt" controlId="form.prompt">
                <Form.Control
                  as="textarea"
                  value={this.state.promptText}
                  onChange={(e) => this.setState({promptText: e.target.value})}
                  style={{height: "200px"}}
                />
              </FloatingLabel>
              <FloatingLabel label="Negative prompt" controlId="form.negative_prompt">
                <Form.Control
                  as="textarea"
                  value={this.state.negativePromptText}
                  onChange={(e) => this.setState({negativePromptText: e.target.value})}
                  style={{height: "200px"}}
                />
              </FloatingLabel>

              {["control_weights", "control_modes"].map((key) => {
                return <FloatingLabel label={key} controlId={"form." + key}>
                  <Form.Control
                    as="input"
                    type="text"
                    value={this.state[key]}
                    onChange={(e) => this.setState({[key]: e.target.value})}
                  />
                </FloatingLabel>
              })}

              <Form.Group>
                <Button onClick={this.handleRegenerateNewPromptButtonClick}>Regenerate</Button>
              </Form.Group>
            </Stack>
          </Col>
          <Col className="pt-3 pb-3">
            <Row>
              {this.state.files.map((file) => <Col key={file.url} xs={1}>
                <Figure onClick={() => this.handleTabClick(file)} style={{margin: 0}}>
                  <Figure.Image
                    src={file.url}
                    rounded
                    className={this.state.file && this.state.file.url === file.url ? "border border-5 border-danger" : ""}
                  />
                </Figure>
              </Col>)}
            </Row>

            <hr />

            <Nav variant="pills" onSelect={(v) => this.handleGroupSelected("group1", v)}>
              {groups1.map((group) => <Nav.Item key={group}>
                <Nav.Link active={group === this.state.group1} eventKey={group}>{group}</Nav.Link>
              </Nav.Item>)}
            </Nav>
            <hr />

            <Nav variant="pills" onSelect={(v) => this.handleGroupSelected("group2", v)}>
              {groups2.map((group) => <Nav.Item key={group}>
                <Nav.Link active={group === this.state.group2} eventKey={group}>{group}</Nav.Link>
              </Nav.Item>)}
            </Nav>
            {/*<hr />*/}

            {/*<Nav variant="pills" onSelect={(v) => this.handleGroupSelected("group3", v)}>*/}
            {/*  {groups3.map((group) => <Nav.Item key={group}>*/}
            {/*    <Nav.Link active={group === this.state.group3} eventKey={group}>{group}</Nav.Link>*/}
            {/*  </Nav.Item>)}*/}
            {/*</Nav>*/}

            <hr />

            <div className="d-flex flex-wrap">
              {creativesItems.map((creative) => <React.Fragment key={creative.id}>
                {creative.isPending && <div style={creativeBoxStyles} className="m-2">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>}
                {creative.isProcessed && <Figure onClick={() => this.handleCreativeClick(creative)}>
                  <Figure.Image
                    src={creative.result}
                    rounded
                    style={{width: "300px"}}
                    className="m-2"/>
                  <Figure.Caption>{creativeName(creative)}</Figure.Caption>
                </Figure>}
                {creative.isFailed && <Alert className="m-2" variant="danger" style={creativeBoxStyles} onClick={() => this.handleCreativeClick(creative)}>
                  Template {creative.templateId}<br />
                  Procesisng failed<br />
                  Code: {creative.error && creative.error.code}<br />
                  Message: {creative.error && creative.error.message}
                </Alert>}
              </React.Fragment>)}
            </div>
          </Col>
        </Row>

      </Container>
    </React.Fragment>;
  }
}

ResultPage.contextType = AppContext;
